import React, { useContext, useState, useEffect  } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import PasswordStrengthBar from 'react-password-strength-bar';
import validator from 'validator'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height:'100vh',
      },
      image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
      },
      paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
        padding:theme.spacing(2, 2, 2, 2),
      },
  }));




// singin


async function LoginUser(credentials) {
  return fetch('https://spkjewelry.com/api/login.php', {
    method: 'POST',
    body: credentials
  })
    .then(data => data.json())
 }



function FSingUp(){  
  const classes = useStyles();

    const [uname, setUserName] = React.useState({
        value: '',
        error: false,
        setValue: (V) => {
            setUserName({...uname, value:V, error:false})
        },
        setError: (V) => {
            setUserName({...uname,value:V.value, error:true})
        },
    });

    const [ulname, setUserLName] = React.useState({
        value: '',
        error: false,
        setValue: (V) => {
            setUserLName({...ulname, value:V, error:false})
        },
        setError: (V) => {
            setUserLName({...ulname,value:V.value, error:true})
        },
    });


    const [email, setEmail] = React.useState({
        value: '',
        error: false,
        setValue: (V) => {
            setEmail({...email, value:V, error:false})
        },
        setError: (V) => {
            setEmail({...email,value:V.value, error:true})
        },
    });

    const [password, setPassword] = React.useState({
        value: '',
        error: false,
        setValue: (V) => {
            setPassword({...password, value:V, error:false})
        },
        setError: (V) => {
            setPassword({...password,value:V.value, error:true})
        },
    });

    const handleSubmit = async e => {
        e.preventDefault();

        var isc = true;
        if(uname.value === ""){
            uname.setError(uname);
            isc = false;
        }
        if(ulname.value === ""){
            ulname.setError(ulname);
            isc = false;
        }
        if(email.value === "" || !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email.value)){
            email.setError(email);
            isc = false;
        }
        if(password.value === ""){
            password.setError(password);
            isc = false;
        }
        
        if(isc){
            Swal.fire("ok", '',"error");
        }



      } 


  return (
    <>
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={0} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={uname.value}
                onChange={e => uname.setValue(e.target.value)}
                error={uname.error}
                helperText={(uname.error) ? 'โปรดกรอกข้อมูลให้ครบถ้วน' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={ulname.value}
                onChange={e => ulname.setValue(e.target.value)}
                error={ulname.error}
                helperText={(ulname.error) ? 'โปรดกรอกข้อมูลให้ครบถ้วน' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email.value}
                onChange={e => email.setValue(e.target.value)}
                error={email.error}
                helperText={(email.error) ? 'กรอกข้อมูลให้ถูกต้อง' : ''}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password.value}
                onChange={e => password.setValue(e.target.value)}
                error={password.error}
                helperText={(password.error) ? 'โปรดกรอกข้อมูลให้ครบถ้วน' : ''}

              />
              <PasswordStrengthBar password={password.value} />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/account" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
        </div>
      </Grid>
    </Grid>
    
    </>
  );
}


  class Singup extends React.Component {

    constructor(props) {
      if(localStorage.getItem('accessToken')){
        window.location.href = '/account';
    }
      super(props)
      this.state = {
        isLoaded: false,
        token : localStorage.getItem('accessToken'),
          _user : JSON.parse(localStorage.getItem('user')),
      }
    }
  
    
  
  
  
  
    
    componentDidMount() {
      window.scrollTo(0, 0)
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.location.search !== this.props.location.search) {
        //const { ProductCat,ProductK} = this.props.match.params;  
      }
    }
  
  
  
    render() {
        let {
          isLoaded,
      } = this.state;
  
      if (isLoaded) {
              return (
                <>
                <div>
                    <section>
                    <div class="box-loading loader10"> </div>
                      </section>
                </div>
                </>
              )
          } else {
              return (
                <>
                <section>
                  <FSingUp />  
                </section>
              </>
                );
        }
  
  
    }
  }
  export default Singup