import React, { useContext, useState, useEffect  } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop:'26px',
    paddingBottom:'26px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding:theme.spacing(2, 2, 2, 2),
  },
}));



function Account(){
  const handleLogout= () => {
    window.localStorage.clear();
    window.location.href = "/account";
  }

  const token = localStorage.getItem('accessToken');
  const _user = JSON.parse(localStorage.getItem('user'));
  return (
    <>
    <div onClick={handleLogout}>Logout</div>
    <div>accountddddddddddddddddddddddd {_user.id}</div>
    </>
  );
}


class Customer extends React.Component {

  constructor(props) {
    if(!localStorage.getItem('accessToken')){
      window.location.href = '/singin';
    }
		super(props)
		this.state = {
      isLoaded: false,
			token : localStorage.getItem('accessToken'),
      _user : JSON.parse(localStorage.getItem('user')),
	  }
  }

	




	
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			//const { ProductCat,ProductK} = this.props.match.params;  
		}
	}



  render() {
    


      let {
        isLoaded,
    } = this.state;

    if (isLoaded) {
            return (
              <>
              <div>
                  <section>
                    <div class="box-loading loader10"> </div>
                  </section>
              </div>
              </>
            )
        } else {
          return (
            <div className="main-body">
            <Account />
            </div>
          );
      }


  }
}
export default Customer