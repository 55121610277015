
import React from 'react'
import Axios from 'axios'
import {Link} from 'react-router-dom'
import { useParams } from "react-router-dom";
import queryString from 'query-string';
import {isMobile} from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { NavLink} from "react-router-dom";
import VanillaTilt from 'vanilla-tilt';



   
  


class Home extends React.Component {



  constructor(props) {
		super(props)
		this.state = {
			isLoaded: false,
			jsonData: [],
			isnull : false,
			cards: [],
		}
	  }
	
	getParam(){
		return this.state.param;
	}

	getProduct(pageurl) {
	

		
		/*
		//const recipeUrl =  'spkjewelry.com/api/test.php';
		const recipeUrl =  'https://spkjewelry.com/api/test.php';

		const postBody = new FormData();
		postBody.append('kkauthorization', 'U1BLNjU0ZHgxMnZoZmVhNjk4N3JnZDEyNXA');
		postBody.append('password', 'John123');
		const requestMetadata = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'

			},
			body: postBody
		};	
		fetch(recipeUrl, requestMetadata)
		.then(response => response.json())
		.then(data => {
			console.log("KKKKKK "+data.status_msg);

		}).catch(error => {
			console.error("KKKKKK "+error.message);
		});
		*/
		
		


		const objss = {  
			method: 'POST',
		}
	fetch(pageurl,objss)
		.then(response => response.json())
		.then(data => {
			this.setState({ isLoaded: true});
			if(data.status_code == "1"){

		
				this.setState({ cards: data.data.map((item, key) => (
					<>
						<div class="card-img-header"><img src="pictures/ads/tmp.jpg" width="100%" /></div>
						<div className="const">
						{item.datas.map((item2, key2) => (
							<div className="card" >
							
									<div className="content">
									<h2>{item2.bodysty}</h2>
									<img src={item2.path_pic} width="100%" />
									<h3>{item2.name}</h3>
									<h4>{item2.sinfo}</h4>
									<a href={"/product/"+item2.id}>รายละเอียด</a>
									</div>
							
							</div>
						))}
						</div>
					</>
				))});



			}else{
				this.setState({cards:this.state.cards.concat(
					<>
					<div class="product-null">ERROR: {data.status_msg}</div>
					</>
				)});
			}
			
		}).catch(error => {
			this.setState({ isLoaded: true})
			this.setState({cards:this.state.cards.concat(
				<>
				<div class="product-null">{error.message}</div>
				</>
			)});
		});


/*
		var data = new FormData();
			data.append("ddd", "ddddd");

		
			var xhr = new XMLHttpRequest();
			var json_obj, status = false;
			xhr.open("POST", "http://spkjewelry.com/api/test.php", true);
			xhr.setRequestHeader("cache-control", "no-cache");
			xhr.onload = function (e) {
				if (xhr.readyState === 4) {
					
					console.log("KKKKKKKK "+xhr.readyState+" "+xhr.status+ " "+xhr.responseText);
				
					
				}else{
					console.log("KKKKKKKK "+xhr.statusText);
				}
				
			}.bind(this);
			xhr.onerror = function (e) {
				console.error("KKKKKKKK ");
			};
			xhr.send(null);
			*/
	}

	
	componentDidMount() {
		window.scrollTo(0, 0);
		this.getProduct(`https://spkjewelry.com/api/get-home.php`);	
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			//const { ProductCat,ProductK} = this.props.match.params;  
		}
	}



  render() {
      let {
        isLoaded,
      jsonData
    } = this.state;

    if (!isLoaded) {
            return (
              <>
              <div>
                  <section>
                  <div class="box-loading loader10"> </div>
                    </section>
              </div>
              </>
            
            
            )
        } else {
        return (
          <>
          <section>
                <div className="main-body">
                    {this.state.cards}
                </div>
            </section>
          </>
          
        )
      }


  }
}
export default Home