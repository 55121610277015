import React, { useContext, useState, useEffect  } from "react";
import { NavLink} from "react-router-dom";
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import { MenuContext } from "react-flexible-sliding-menu";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles({
    list: {
      width: 'auto',
    },
    fullList: {
      width: 'auto',
    },
  });


  function SetParam(sty, code){
    const params = queryString.parse(window.location.search);
    const param = "body="+((params.body) ? params.body : "all")+"&cat="+((params.cat) ? params.cat : "all")+"&price="+((params.price) ? params.price : "all")+"";
    switch(sty){
        case "body" :
            return "/products?body="+code+"&cat="+((params.cat) ? params.cat : "all")+"&price="+((params.price) ? params.price : "all")+"";
            break;
        case "cat" :
            return "/products?body="+((params.body) ? params.body : "all")+"&cat="+code+"&price="+((params.price) ? params.price : "all")+"";
            break;
        case "price" :
            return "/products?body="+((params.body) ? params.body : "all")+"&cat="+((params.cat) ? params.cat : "all")+"&price="+code+"";
            break;
    }
    
    return param;
  }

  function SetActiveMenu(sty, value){
        const css_name = "menu-active";
        const params = queryString.parse(window.location.search);
        let _vck = "";
        if(sty == "price"){
            _vck = ((params.price) ? params.price : "all");
            if(_vck == value){
                return css_name;
            }
        }
        if(sty == "body"){
            _vck = ((params.body) ? params.body : "all");
            if(_vck == value){
                return css_name;
            }
        }
        if(sty == "cat"){
            _vck = ((params.cat) ? params.cat : "all");
            if(_vck == value){
                return css_name;
            }
        }
        return "";
  }


  function MenuDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setState({ ...state, [anchor]: open });
    };
    const params = queryString.parse(window.location.search)
    const param = "";
    const { closeMenu } = useContext(MenuContext);
    const [dd, setDD] = useState([]);

    

    useEffect(() => {

        fetch(`https://spkjewelry.com/api/get-cat.php${param}`)
                .then(response => response.json())
                .then(data => {
                if(data.status_code == "1"){
                    setDD(data.dd);
                }
                });
            
                
                
    },[])

    
  
    const list = (anchor) => (
      <div 
        className={clsx(classes.list, {
            [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        >
        <div className="Menu" >
        <div class="menu-close" >
          <p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          </p>
        </div>
    <nav >
   
        <ul>
        
          <li class="menu-home"><NavLink to="/" > <span>หน้าหลัก</span></NavLink></li>
          <li class="menu-home"><NavLink to="/account"> <span>บัญชีผู้ใช้</span></NavLink></li>
          <li class="menu-home"><NavLink to="/products"> <span>สินค้าทั้งหมด</span></NavLink></li>
                {
                    dd.map((items) => 
                        <>
                        <li class="menu-title-header"><span>{items.name}</span></li>
                        <ul class={"menu-"+items.classname+""}>
                        { 
                            items.data.map((sub, subindex) =>
                            <li className={SetActiveMenu(items.pref,sub.code)}><NavLink to={SetParam(items.pref,sub.code)}> <span>{sub.name} </span></NavLink></li>
                            )
                        }
                        </ul>
                        </>
                    )
                }
       
          
        </ul>
    </nav>
    
  </div>
      </div>
    );
  
    return (
        <>
        <div className="menu-berger" onClick={toggleDrawer('left', true)}>
                        <div className="menu-berger-line-1"></div>
                        <div className="menu-berger-line-2"></div>
                        <div className="menu-berger-line-3"></div>
                    </div>
                    <Drawer anchor='left' open={state['left']} onClose={toggleDrawer('left', false)}>{list('left')}</Drawer>
       </>     
        
    );
  }






const header = React.memo(() => {

    const [anchorEl, setAnchorEl] = React.useState(null);



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setLangTH = () => {
    const select = document.getElementsByClassName('goog-te-combo')[0];
        select.selectedIndex = 0;
        if(select.value == "" || select.value == "en"){
            select.selectedIndex = 1;
        }
        if(select.value == "th" ){
            select.addEventListener('click', function () {
                select.dispatchEvent(new Event('change'));
            });
            select.click();
            setAnchorEl(null);
        }
  };
  const setLangEN = () => {
    const select = document.getElementsByClassName('goog-te-combo')[0];
        select.selectedIndex = 0;
        if(select.value == "" || select.value == "th"){
            select.selectedIndex = 1;
        }
        if(select.value == "en" ){
            select.addEventListener('click', function () {
                select.dispatchEvent(new Event('change'));
            });
            select.click();
            setAnchorEl(null);
        } 
  };
    
    const { toggleMenu } = useContext(MenuContext);
    return (
        <>
            <header>
                <nav className="header-menu">
                    <MenuDrawer />
                    <div className="header-logo">
                        <h4>SPK Jewelry</h4>
                    </div>
                    <div>

      <Menu
        className="notranslate"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={setLangTH}>ไทย</MenuItem>
        <MenuItem onClick={setLangEN}>English</MenuItem>
      </Menu>

                    </div>
                    <ul>
                    <li className="notranslate language-select" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>TH/EN</li>
                    </ul>
                </nav>
            </header>
            <div class="header-padding">&nbsp;</div>  
        </>
    )

    /*if(isMobile) {
        return (
            <>
                    <div class="box-menu-header-mobile">
                        <ul>
                            <li class="menu" onClick={toggleMenu}>&nbsp;</li>
                            <li class="logo">&nbsp;</li>
                            <li class="right notranslate language-select" onClick={setLangTH}><span>TH</span></li>
                            <li class="right notranslate language-select" onClick={setLangEN}><span>EN</span></li>
                         
                        </ul>
                    </div>
                    <div class="header-padding-mobile">&nbsp;</div>
            </>
        )
    }else{
        return (
            <>
                <header>
                    <nav className="header-menu">
                        <div className="menu-berger">
                            <div className="menu-berger-line-1"></div>
                            <div className="menu-berger-line-2"></div>
                            <div className="menu-berger-line-3"></div>
                        </div>
                        <div className="header-logo">
                            <h4>SPK Jewelry</h4>
                        </div>
                        <ul>
                            <li><span>TH</span></li>
                            <li><span>EN</span></li>
                        </ul>
                    </nav>
                </header>

                
            </>
        )
    }*/
});



export default header;

/*<div class="box-menu-header">
                    <ul>
                        <li class="menu" onClick={toggleMenu}></li>
                        <li class="logo">&nbsp;</li>
                        <li class="right notranslate language-select" onClick={setLangTH}><span>TH</span></li>
                        <li class="right notranslate language-select"  onClick={setLangEN}><span>EN</span></li>
                    </ul>
                </div>
                <div class="header-padding">&nbsp;</div>
                */