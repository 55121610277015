import React from 'react'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect';





export default function NotFound() {
   
    return (
        <>
        <div class="page-notfound">404 Page NotFound</div>
        </>
    )
}