
import React from 'react'
import { useParams } from "react-router-dom";
import queryString from 'query-string';
import {isMobile} from 'react-device-detect';
import { withRouter } from 'react-router-dom';

function Getparam(){
        const { ProductId } = useParams();
        return ProductId;
}

class Productdetail extends React.Component {

 

	constructor(props) {
		super(props)
      this.handleClickShare = this.handleClickShare.bind(this)
      this.state = {
        pid: 0,
        p_title: "",
        p_link: "",
        p_pic: "",
        isNotFound: false,
        isLoaded: false,
        jsonData: [],
        isnull : false,
        btn_social : [
                  {
                      title: "0982246245",
                      icon: "https://spkjewelry.com/products/picture/icon-phone.png",
                      link: "tel:0982246245",
                      style_color: "btn-phone-color",
                      color:"txt-w",
                  },
                  {
                    title: "TikTok",
                    icon: "https://spkjewelry.com/products/picture/icon-tik-tok.png",
                    link: "https://vt.tiktok.com/ZSTQW9k4/",
                    style_color: "btn-tiktok-color",
                    color:"txt-b",
                  },
                  {
                    title: "Instagram",
                    icon: "https://spkjewelry.com/products/picture/icon-instagram.png",
                    link: "https://www.instagram.com/invites/contact/?i=ek54alo0p61w&utm_content=2y6lx3p",
                    style_color: "btn-instagram-color",
                    color:"txt-w",
                  },
                  {
                    title: "Youtube",
                    icon: "https://spkjewelry.com/products/picture/icon-youtube.png",
                    link: "https://youtube.com/channel/UCb8TIkBXwfvr_zZWiZPuHLQ",
                    style_color: "btn-youtube-color",
                    color:"txt-w",
                  },
                  {
                    title: "Facebook",
                    icon: "https://spkjewelry.com/products/picture/icon-facebook.png",
                    link: "https://www.facebook.com/spkjewelry/",
                    style_color: "btn-facebook-color",
                    color:"txt-w",
                  },
                  {
                    title: "Messenger",
                    icon: "https://spkjewelry.com/products/picture/icon-messenger.png",
                    link: "https://m.me/spkjewelry",
                    style_color: "btn-messenger-color",
                    color:"txt-w",
                  },
                  {
                    title: "Twitter",
                    icon: "https://spkjewelry.com/products/picture/icon-twitter.png",
                    link: "https://twitter.com/spkjewelry",
                    style_color: "btn-twitter-color",
                    color:"txt-w",
                  },

              ]
      }
	  }

    Get(pid) {
      fetch(`https://spkjewelry.com/api/get-product-detail.php?id=${pid}`)
          .then(response => response.json())
          .then(data => {
            if(data.status_code == "1"){
              this.setState({ jsonData: data, isLoaded: true, p_title:data.datas.name, p_desc:data.datas.sinfo, p_link:"https://shopping.spkjewelry.com/product/"+data.datas.id})
            }else{
              this.setState({isNotFound:true, jsonData: data, isLoaded: true, p_title:"ไม่พบสินค้า", p_desc:"ไม่พบสินค้า หรือสินค้านี้ไม่มีอยู่อีกแล้ว", p_link:"https://shopping.spkjewelry.com"})
            }
            this.setMetaHtml();
          });
      }

      handleClickShare = () => {
        if (navigator.share) {
          navigator
            .share({
              title: this.state.p_title,
              text: this.state.p_desc,
              url: this.state.p_link,
            })
            .then(() => {
              console.log("Successfully shared");
            })
            .catch((error) => {
              console.error("Something went wrong", error);
            });
      }
    };

    setMetaHtml(){
      document.title = this.state.p_title;
      document.description = this.state.p_desc;
    }	

    componentDidMount() {
        const { ProductId } = this.props.match.params;
        //let params = queryString.parse(this.props.location.search)
        this.setState({pid: ProductId});
        this.Get(ProductId);
        window.scrollTo(0, 0)

    }
    componentDidUpdate(prevProps) {
      
      if (prevProps.match.params !== this.props.match.params) {
        const { ProductId } = this.props.match.params;
        this.setState({pid: ProductId});
        this.Get(ProductId);
        window.scrollTo(0, 0)
      }
  }

  render() {
      let {
            btn_social,
            pid,
            isNotFound,
            isLoaded,
            jsonData
      } = this.state;
  
        
      if (!isLoaded) {
          return (<div class="box-loading loader10"> </div>)
      } else {
        if(isNotFound){
          return (<div class="box-data-json-error">ไม่พบสินค้า</div>)
        }
        if(isMobile) {
          return (
            <>
            <div >

              <div style={{display:'block',width:'100%'}}><img src={"https://spkjewelry.com/products/picture/products/"+this.state.pid+"/main.jpg"} width="100%" /></div>
              
              <div class="prod-detail-txt">
              <div style={{display:'block',width:'100%',fontWeight:'600'}}>{jsonData.datas.name}</div>
              <div style={{display:'block',width:'100%',fontWeight:'400'}}>{jsonData.datas.sinfo}</div>
              <div style={{display:'block',width:'100%',fontSize:'2em',fontWeight:'700',color:'#CD312F'}}>{jsonData.datas.price_f} 
                <span style={{fontSize:'0.8em',fontWeight:'400',color:'rgba(0,0,0,0.5)',textDecoration:'line-through',marginLeft:'20px'}}>ปกติ {jsonData.datas.rprice_f}</span>
              </div>
              <div style={{borderBottom:'1px dotted rgba(0,0,0,0.2)',lineHeight:'1px',marginBottom:'10px',marginTop:'10px'}}>&nbsp;</div>
                <ul class="prod-detail-box">
                  <li>เก็บเงินปลายทาง</li>
                  <li>ส่งฟรี</li>
                  <li>ปรับไซด์ฟรี</li>
                  <li>เลือกสีตัวเรือนได้</li>
                </ul>
              <div style={{borderBottom:'1px dotted rgba(0,0,0,0.2)',lineHeight:'1px',marginBottom:'10px',marginTop:'10px'}}>&nbsp;</div>
              <div class="facebook">
                        <iframe src="https://www.facebook.com/plugins/like.php?href=https://www.facebook.com/spkgold&width=450&layout=standard&action=like&size=small&show_faces=false&share=true&height=35&appId=222100227844664" width="450" height="35" style={{border:'none',overflow:'hidden'}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
              </div>
              </div>

              <div>
              {
                    jsonData.datas_video.map((items =>
                      
                      <video width="100%" poster={items.poster}  controls autoplay>
                        <source src={items.src} type="video/mp4" />
                      Your browser does not support the video tag.
                      </video>
                    ))
                }
              </div>
              <div>
              {
                    jsonData.datas_picture.map((items =>
                      <img src= {items.src} width="100%" />
                    ))
                }
                <img src="https://spkjewelry.com/products/picture/how-to-size.jpg" width="100%" />
                <img src="https://spkjewelry.com/products/picture/how-to-size2.jpg" width="50%" />
                <img src="https://spkjewelry.com/products/picture/how-to-size3.jpg" width="50%" />
              </div>

              {/*
              <section style={{paddingTop:'80px',paddingBottom:'80px',background:'rgb(221,244,207)',background:'linear-gradient(147deg, rgba(158,218,158,1) 0%, rgba(221,244,207,1) 100%)'}}>
                <div style={{padding:'10px',textAlign:'center'}}>

                <div style={{ display:'block',fontSize:'3em',color: '#1D9A16',fontWeight:'700'}}>สนใจ/สอบถาม</div>
                        <div style={{ display:'inline-block',fontSize:'1.8em',color: '#F33',fontWeight:'600',marginBottom:'8px', marginTop:'8px'}}>@spkjewel</div>
                          <div style={{color:'#154B07',fontWeight:'bold'}}><img src="https://spkjewelry.com/products/picture/line-qrcode.png" width="200" /></div>
                          <div style={{color:'#154B07',fontSize:'0.8em', marginBottom:'16px'}}> </div>
                          <div><img src="https://spkjewelry.com/products/picture/ico-line-add.png" width="220" onclick="location.href='https://lin.ee/6AufMsr'" style={{cursor:'pointer'}} /></div>
                          
                          </div>
              </section>

              <section style={{backgroundColor:'#F3453A', paddingTop:'20px', paddingBottom:'20px'}} >	
                  <div style={{display:'block', textAlign:'center', fontSize:'2.5em', fontWeight:'bold', marginTop:'20px', marginBottom:'20px', color:'#FFF'}}>หรือติดต่อ</div>

                  {
                    btn_social.map((items =>
                      <div class="box-btn-social-custom" >
                        <a href={items.link} target="_blank" style={{textdecoration:'none'}}>
                          <div class={"btn-social-custom "+items.style_color+""} > 
                              <div class="btn-social-custom-icon"><img src={items.icon} /></div>
                              <div class={"btn-social-custom-title "+items.color+""}  >{items.title}</div>
                            </div>
                            </a>
                          </div>
                    ))
                }

                </section> 
              */}
                <div style={{marginBottom:'100px'}}> </div>
                
                <div class="box-bottom-menu">
                  <ul>
                      <li><a href="https://lin.ee/6AufMsr">สอบถาม</a></li>
                      <li><a href="#Share" onClick={this.handleClickShare}>แชร์</a></li>
                      <li class="right btn-buy"><a href={"/checkout/"+jsonData.datas.id+""}>ซื้อเลย</a></li>
                    </ul>
                </div>

                

            </div>
            </>  
          )
        }else{
          return (
            <>

            <div class="box-prod-pc-main" >
              <div class="box-prod-pc-left">
                <div>

                  <div style={{display:'block',width:'100%'}}><img src={"https://spkjewelry.com/products/picture/products/"+this.state.pid+"/main.jpg"} width="100%" /></div>
                  <div>
                    {
                          jsonData.datas_video.map((items =>
                            
                            <video width="100%" poster={items.poster}  controls autoplay>
                              <source src={items.src} type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                          ))
                      }
                    </div>
                    <div>
                    {
                          jsonData.datas_picture.map((items =>
                            <img src= {items.src} width="100%" />
                          ))
                      }
                      <div id="size-measurement">
                      <img src="https://spkjewelry.com/products/picture/how-to-size.jpg" width="100%" />
                      <img src="https://spkjewelry.com/products/picture/how-to-size2.jpg" width="50%" />
                      <img src="https://spkjewelry.com/products/picture/how-to-size3.jpg" width="50%" />
                      </div>
                    </div>

                </div>
                </div><div class="box-prod-pc-right">
                <div class="box-prod-pc-right-in">

                  <div style={{display:'block',width:'100%',fontSize:'1.6em',fontWeight:'600'}}>{jsonData.datas.name}</div>
                  <div style={{display:'block',width:'100%',fontSize:'1.2em',fontWeight:'500'}}>{jsonData.datas.sinfo}</div>
                  <div style={{display:'block',width:'100%',fontSize:'2em',fontWeight:'700',color:'#CD312F'}}>{jsonData.datas.price_f} 
                    <span style={{fontSize:'0.7em',fontWeight:'400',color:'#CCCCCC',textDecoration:'line-through',marginLeft:'40px'}}>ปกติ {jsonData.datas.rprice_f}</span>
                  </div>
                  <div style={{borderBottom:'1px dotted rgba(0,0,0,0.2)',lineHeight:'1px',marginBottom:'10px',marginTop:'10px'}}>&nbsp;</div>
                  <ul class="prod-detail-box">
                    <li>เก็บเงินปลายทาง</li>
                    <li>ส่งฟรี</li>
                    <li>ปรับไซด์ฟรี</li>
                    <li>เลือกสีตัวเรือนได้</li>
                  </ul>

                <div style={{borderBottom:'1px dotted rgba(0,0,0,0.2)',lineHeight:'1px',marginBottom:'10px',marginTop:'10px'}}>&nbsp;</div>
                  <div class="facebook">
                      <iframe src="https://www.facebook.com/plugins/like.php?href=https://www.facebook.com/spkgold&width=450&layout=standard&action=like&size=small&show_faces=false&share=true&height=35&appId=222100227844664" width="450" height="35" style={{border:'none',overflow:'hidden'}} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                  </div>

                 
                  <div class="box-prod-pc-nav-box">
                  <ul>
                      <li><a href="https://lin.ee/6AufMsr">สอบถาม</a></li>
                      <li><a href="#Share" onClick={this.handleClickShare}>แชร์</a></li>
                    </ul>
                  </div>
                  <div style={{borderBottom:'1px dotted rgba(0,0,0,0.2)',lineHeight:'1px',marginBottom:'10px',marginTop:'10px'}}>&nbsp;</div>

                  <div class="box-prod-pc-buy-box" ><a href={"/checkout/"+jsonData.datas.id+""}>ซื้อเลย</a></div>

                  <div style={{borderBottom:'1px dotted rgba(0,0,0,0.2)',lineHeight:'1px',marginBottom:'10px',marginTop:'10px'}}>&nbsp;</div>
                  <div class="box-prod-pc-nav-box">
                  <ul>
                      <li><a href="#size-measurement">วิธีวัดไซด์</a></li>
                    </ul>
                  </div>
                </div>
                
              </div>
            </div>

            </>
          )
        }
    }
  }
}
export default Productdetail