import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './loading.css';
import {isMobile} from 'react-device-detect';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Route,Switch } from "react-router-dom";
import MenuProvider from "react-flexible-sliding-menu";

import Menu from './components/menu'
import Home from './components/home';
import Products from './components/products';
import Header from './components/header'
import Footer from './components/footer'
import Productdetail from './components/productdetail'
import Checkout from './components/checkout'
import Customer from './components/customer'
import Singin from './components/singin'
import Singup from './components/singup'
import NotFound from './components/notfound'


const Routing = () => {
  let _w = "50%";
  if(isMobile) {
    _w = "100%";
  }


    return(
      <BrowserRouter>
        <MenuProvider MenuComponent={Menu} width={_w}>
          <Header/> 
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/products" component={Products} />
              <Route path="/product/:ProductId" component={Productdetail} />
              <Route path="/checkout/:ProductId" component={Checkout} />
              <Route path="/account" component={Customer} />
              <Route path="/singin" component={Singin} />
              <Route path="/singup" component={Singup} />
              <Route path='*' exact={true} component={NotFound} />
            </Switch>
          <Footer/>
        </MenuProvider>
      </BrowserRouter>
    )
}


ReactDOM.render(
 
     <Routing />

  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
