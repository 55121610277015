import React, { useContext, useState, useEffect  } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding:theme.spacing(2, 2, 2, 2),
  },
}));




// singin

async function LoginUser(credentials) {
  return fetch('https://spkjewelry.com/api/login.php', {
    method: 'POST',
    body: credentials
  }).then(data => data.json())
 }



function Singinbox(){

  const classes = useStyles();

  

  const [username, setUserName] = React.useState({
      value: '',
      error: false,
      setValue: (V) => {
        setUserName({...username, value:V, error:false})
      },
      setError: (V) => {
        setUserName({...username,value:V.value, error:true})
      },
  });

  const [password, setPassword] = React.useState({
      value: '',
      error: false,
      setValue: (V) => {
        setPassword({...password, value:V, error:false})
      },
      setError: (V) => {
        setPassword({...password,value:V.value, error:true})
      },
  });


  const handleSubmit = async e => {
    e.preventDefault();

    var isc = true;
    if(username.value === ""){
        username.setError(username);
        isc = false;
    }
    if(password.value === ""){
        password.setError(password);
        isc = false;
    }

    if(isc){
      const postBody = new FormData();
      postBody.append('user', username.value);
      postBody.append('passw', password.value);
  
      const response = await LoginUser(postBody);
      if (response.status_code == "1") {
         
        Swal.fire({
          title:"เข้าสู่ระบบแล้ว", 
          icon:"success",
          showConfirmButton: false,
          timer: 1000
        })
        .then((value) => {
          console.log("kkkkkkkkkkk"+response.data.id);
          localStorage.setItem('accessToken', response.data.id);
          localStorage.setItem('user', JSON.stringify(response.data));
          window.location.href = "/account";
        });
      } else {
        Swal.fire("เข้าสู้ระบบไม่สำเร็จ", response.status_msg, "error");
      }
    }
    
  }

  return (
    <>
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={0} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              onChange={e => username.setValue(e.target.value)}
              error={username.error}
              helperText={(username.error) ? 'โปรดกรอกข้อมูลให้ครบถ้วน' : ''}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={e => password.setValue(e.target.value)}
              error={password.error}
              helperText={(password.error) ? 'โปรดกรอกข้อมูลให้ครบถ้วน' : ''}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/singup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            
          </form>
        </div>
      </Grid>
    </Grid>
    
    </>
  );
}



class Singin extends React.Component {

  constructor(props) {
    if(localStorage.getItem('accessToken')){
        window.location.href = '/account';
    }
		super(props)
		this.state = {
      isLoaded: false,
			token : localStorage.getItem('accessToken'),
        _user : JSON.parse(localStorage.getItem('user')),
	  }
  }

	




	
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			//const { ProductCat,ProductK} = this.props.match.params;  
		}
	}



  render() {
      let {
        isLoaded,
    } = this.state;

    if (isLoaded) {
            return (
              <>
              <div>
                  <section>
                  <div class="box-loading loader10"> </div>
                    </section>
              </div>
              </>
            )
        } else {
            return (
                <>
                  <section>
                    <Singinbox />
                  </section>
                </>
              );
      }


  }
}
export default Singin