
import React from 'react'
import { useParams } from "react-router-dom";
import queryString from 'query-string';
import {isMobile} from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { NavLink} from "react-router-dom";
import VanillaTilt from 'vanilla-tilt';



function GetParamDesc(){
    const params = queryString.parse(window.location.search);
    //const param = ("รายการสินค้า ตัวเลือกทองคำ <h3>"+params.body+"</h3> แคตตาล็อก <h3>"+params.cat+"</h3> ราคา <h3>"+params.price+"</h3>");
    return(
        <div>
            รายการสินค้า ตัวเลือกทองคำ <p>{params.body}</p> แคตตาล็อก <p>{params.cat}</p> ราคา <p>{params.price}</p>
        </div>
    );
}
   
  


class Product extends React.Component {



  constructor(props) {
		super(props)
		this.loadMore = this.loadMore.bind(this)
		this.state = {
			isLoaded: false,
			jsonData: [],
			page:1,
			isnull : false,
			cards: [],
			filter: "",
			param:"this.props.location.search"
		}
	  }
	
	getParam(){
		return this.state.param;
	}

	getProduct(pageurl) {
		fetch(pageurl)
				.then(response => response.json())
				.then(data => {
					
					this.setState({ jsonData: data, isLoaded: true})

					var count = Object.keys(data.datas).length;
	
					if(count == 0){
						this.setState({cards:this.state.cards.concat(<div class={"product-null"}>ไม่พบสินค้าที่ท่านต้องการ</div>	)});
					}
					

          this.setState({ cards: this.state.cards.concat(data.datas.map((item, key) => (

                  <div className="card">
                          <div className="content">
                            <h2>{item.bodysty}</h2>
                            <img src={item.path_pic} width="100%" />
                            <h3>{item.name}</h3>
                            <h4>{item.sinfo}</h4>
                            <a href={"/product/"+item.id}>รายละเอียด</a>
                          </div>
                        </div>

     
              
            
          )))})
					/*if(isMobile) {
						this.setState({ cards: this.state.cards.concat(data.datas.map((item, key) => (
										<div style={{display:'inline-block',width:'50%'}}><div style={{padding:'4px'}}><a  href={"/product/"+item.id} target="_blank">
												<div style={{position:'relative', display:'block'}}>
													<img src={item.path_pic} width="100%" />
															<div  class="box-prod-btn-detail">ดูรายละเอียด</div>		
												</div>
												</a>
												</div></div>
											
										
									)))})
					}else{
						this.setState({ cards: this.state.cards.concat(data.datas.map((item, key) => (
							<div style={{display:'inline-block',width:'25%'}}><div style={{padding:'4px'}}><a href={"/product/"+item.id} target="_blank">
									<div style={{position:'relative', display:'block'}}>
										<img src={item.path_pic} width="100%" />
												<div  class="box-prod-btn-detail">ดูรายละเอียด</div>		
									</div>
									</a>
									</div></div>
								
							
						)))})
					
					}*/
					if(data.isnull == "0"){
						this.setState({isnull: true})
					}else{
						this.setState({isnull: false})
					}
					this.setState({cards:this.state.cards.concat(<div class={"load-more load-more-"+this.state.page+""}><span>Page {this.state.page}</span></div>	)});
			
					
					
					/*for (var i = 0; i < 100; i++) {
						var temp = (<div>{i}</div>);
						this.setState({ cards: this.state.cards.concat(temp)})
	
					}*/
				});
	  }

	
	componentDidMount() {
		window.scrollTo(0, 0);
		//this.getProduct(`https://shopping.spkjewelry.com/api/get-product.php?page=${this.state.page}`);
    	//document.title = 'Product';
        //document.description = 'Product';
		this.setState({ page: 1 , param:this.props.location.search});
		this.getProduct(`https://spkjewelry.com/api/get-product.php?page=1&${this.props.location.search.replace("?", "")}`);
		const aa =	this.props.location.search;
			
			
    VanillaTilt.init(document.querySelectorAll(".card"), {
      max: 25,
      speed: 400,
      glare:true,
      "max-glare":0.5,
    });
    
    //It also supports NodeList
    VanillaTilt.init(document.querySelectorAll(".card"));
		
	}

    

	componentDidUpdate(prevProps) {
      
		if (prevProps.location.search !== this.props.location.search) {
			//const { ProductCat,ProductK} = this.props.match.params;
			window.scrollTo(0, 0);
			const query = new URLSearchParams(this.props.location.search);
			this.setState({ page: 1, cards: [], param:this.props.location.search});
			this.getProduct(`https://spkjewelry.com/api/get-product.php?page=1&${this.props.location.search.replace("?", "")}`);
			console.log("KKKKK "+`https://spkjewelry.com/api/get-product.php?page=1&${this.props.location.search.replace("?", "")}`);
			//alert(`https://spkjewelry.com/api/get-product.php?page=1&${this.props.location.search.replace("?", "")}`);
			  
		}
	}
	
	componentWillMount(){
	  window.addEventListener('scroll', this.loadMore);
	}
	
	componentWillUnmount(){
		window.removeEventListener('scroll', this.loadMore);
	}
	
	loadMore(){
		if(!this.state.isnull){
			let paddingsc = 0;
			if(isMobile) {
				paddingsc = 1000;
			}else{
				paddingsc = 500;
			}

			const _load_more = document.querySelector(".load-more-"+this.state.page+"");
			
				if(_load_more){
					console.log("KKKKK "+_load_more.offsetTop+ " "+(window.innerHeight +document.documentElement.scrollTop));
				
						
						
						//alert(document.documentElement.scrollTop);
						if (_load_more.offsetTop <= (window.innerHeight +document.documentElement.scrollTop)) {
							//_load_more.remove();
							this.setState({ page: (this.state.page+1) });
							this.getProduct(`https://spkjewelry.com/api/get-product.php?page=${this.state.page}&${this.props.location.search.replace("?", "")}`);
							//alert(rect_load_more.top+" "+document.documentElement.scrollTop);
						}
					
				}
				//alert(rect.top+" "+document.documentElement.scrollTop);
				/*
				if (window.innerHeight + document.documentElement.scrollTop+paddingsc >= document.scrollingElement.scrollHeight) {
					//this.state.page = (this.state.page+1);
					this.setState({ page: (this.state.page+1) });
					//alert(this.props.location.search);
					this.getProduct(`https://spkjewelry.com/api/get-product.php?page=${this.state.page}&${this.props.location.search.replace("?", "")}`);
					
					//alert(this.state.page);
				}
				*/
		}
	}
  


  render() {
      let {
        isLoaded,
      jsonData
    } = this.state;

    if (!isLoaded) {
            return (
              <>
              <div>
                  <section>
                  <div class="box-loading loader10"> </div>
                    </section>
              </div>
              </>
            
            
            )
        } else {
        return (
          <>
              <section>
                <div className="main-body">
                    <div className="const">{this.state.cards}</div>
                </div>
            </section>
          </>
          
        )
      }


  }
}
export default Product