import React, { useContext, useState, useEffect  } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { NavLink} from "react-router-dom";
import { Link} from 'react-router-dom';
import { withRouter } from 'react-router'


function Menu() {

  const param = window.location.search;

  const { closeMenu } = useContext(MenuContext);
  const [datamain, setDataMain] = useState([]);
  const [datas, setData] = useState([]);
  const [datasname, setDataName] = useState([]);
  const [datag, setDataG] = useState([]);
  const [datagname, setDataGName] = useState([]);
  const [datap, setDataP] = useState([]);
  const [datapname, setDataPName] = useState([]);

  

  useEffect(() => {

    fetch(`https://spkjewelry.com/api/get-cat.php${param}`)
            .then(response => response.json())
            .then(data => {
              if(data.status_code == "1"){
                setDataMain(data.datas);
                setData(data.datas.data);
                setDataName(data.datas.name);
                setDataG(data.datag.data);
                setDataGName(data.datag.name);
                setDataP(data.datap.data);
                setDataPName(data.datap.name);
              }
            });
           
            
            
  },[])



    

  return (
    <div className="Menu menu-width" >
    
        <button onClick={closeMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      
      <h1>เมนู</h1>
      <nav onClick={closeMenu}>
        <ul>
          <li class="menu-home"><NavLink to="/" activeClassName="active"> <span>หน้าหลัก</span></NavLink></li>
          <li class="menu-home"><NavLink to="/products"> <span>สินค้าทั้งหมด</span></NavLink></li>
          {
                    datamain.map((items =>
                      <li class="menu-title-header"><span>{items.name}</span></li>
                    ))
                } 

          <li class="menu-title-header"><span>{datapname}</span></li>
          {
                    datap.map((items =>
                      <li><NavLink to={"/products?"+items.link}> <span>{items.name}</span></NavLink></li>
                    ))
                } 
          <li class="menu-title-header"><span>{datagname}</span></li>
            <ul class="menu-ex-inline">
              {
                    datag.map((items =>
                      <li><NavLink to={"/products?"+items.link}> <span>{items.name}</span></NavLink></li>
                    ))
                }
            </ul>
          <li class="menu-title-header"><span>{datasname}</span></li>
          <ul class="menu-cat-inline">
                {
                    datas.map((items =>
                      <li><NavLink to={"/products?"+items.link}> <span>{items.name}</span></NavLink></li>
                    ))
                }
            </ul>
          
        </ul>
      </nav>
      
    </div>
  );
}

export default Menu;
