import React, { useContext, useState, useEffect  } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding:theme.spacing(2, 2, 2, 2),
  },
  green: {
    color: '#fff',
    backgroundColor:'#3bb800',
  },
});








class Checkout extends React.Component {

  constructor(props) {
		super(props)
		this.state = {
            isLoaded: false,
            pid:"",
            productData:[],
            f_provinces: ({value: '',error: false}),
            f_districts: ({value: '',error: false}),
            f_sub_district: ({value: '',error: false}),
            f_zipcode: ({value: '',error: false}),
            f_name: ({value: '',error: false}),
            f_lname: ({value: '',error: false}),
            f_phone: ({value: '',error: false}),
            f_address: ({value: '',error: false}),
            f_info: ({value: '',error: false}),
            f_paysty: ({value: '',error: false}),
            dataProvinces: [],
            dataDistricts: [],
            dataSubDistricts: [],
            boxselectDistricts: "",
            boxselectSubDistricts: "",
            boxZipcopde: "",
        }
  }

  GetProductDetail(pid) {
     
    fetch(`https://spkjewelry.com/api/get-product-detail.php?id=${pid}`)
        .then(response => response.json())
        .then(data => {
          if(data.status_code == "1"){
            this.setState({ productData: data.datas, isLoaded:false});
            //console.log("kkkkkkkk"+data.datas.name);
          }else{
            window.location.href = "/product-notfound";
          }
        });
        
    }

  handleSubmit (e){
    e.preventDefault();
    
    //console.log("kkkkkkkkkkkkkk BB "+this.state.f_namet.value +this.state.f_name+ " "+this.state.f_lname+ " "+this.state.f_phone+ " "+this.state.f_info+ " "+this.state.f_paysty );
    //console.log("kkkkkkkkkkkkkk SUBMIT f_provinces "+this.state.f_provinces+" f_districts "+this.state.f_districts+" f_sub_district "+this.state.f_sub_district+" f_zipcode "+this.state.f_zipcode);
    
    if(this.state.f_name.value == ""){
        this.setState({f_name: {...this.state.f_name, error:true} });    
    }
    if(this.state.f_lname.value == ""){
      this.setState({f_lname: {...this.state.f_lname, error:true} });  
    }  
    if(this.state.f_phone.value == ""){
      this.setState({f_phone: {...this.state.f_phone, error:true} });  
    }  
    if(this.state.f_address.value == ""){
      this.setState({f_address: {...this.state.f_address, error:true} });  
    }  
    if(this.state.f_paysty.value == ""){
      this.setState({f_paysty: {...this.state.f_paysty, error:true} });  
    }  
    if(this.state.f_provinces.value == ""){
      this.setState({f_provinces: {...this.state.f_provinces, error:true} });  
    }  
    if(this.state.f_districts.value === ""){
      this.setState({f_districts: {...this.state.f_districts, error:true} });  
      console.log("kkkkkkkkkkkkkkkk 5555 " + this.state.f_districts.value);
    }  
    if(this.state.f_sub_district.value == ""){
      this.setState({f_sub_district: {...this.state.f_sub_district, error:true} });  
    }  
    if(this.state.f_zipcode.value == ""){
      this.setState({f_zipcode: {...this.state.f_zipcode, error:true} });  
    }
    
    //console.log("kkkkkkkkkkkkkk SUBMIT f_provinces "+this.state.f_provinces.value+" f_districts "+this.state.f_districts.value+" f_sub_district "+this.state.f_sub_district.value+" f_zipcode "+this.state.f_zipcode.value);
    

  } 
  handleInput = ({ target }) =>  {
    this.setState({ [target.name]: {value:target.value, error:((target.value!="") ? false : true)} });     
  }
  handleInputChangePhone = ({ target }) =>  {    
    
    const re = /^0[0-9]{0,9}$/;
    if (re.test(target.value)) {
        this.setState({f_phone: {value:target.value, error:false} });  
    }
  }    


  handleSelectProvinces(e) {
    e.preventDefault();
    const { classes } = this.props;
    const id = e.target.value;  
    fetch(`https://spkjewelry.com/api/get-districts.php?id=${id}`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        document.getElementById("box-districts").style.display = "block";
        document.getElementById("box-sub-districts").style.display = "none";
        document.getElementById("box-zipcode").style.display = "none";
        this.setState({f_provinces: {value:id, error:((id!="") ? false : true)} });  
        this.setState({f_districts: {value:"", error:false} });  
        this.setState({f_sub_district: {value:"", error:false} });  
        this.setState({f_zipcode: {value:"", error:false} });  
        this.setState({dataDistricts:data.datas});
    });

    
  } 

  handleSelectDistricts(e) {
    e.preventDefault();
    const { classes } = this.props;
    const id = e.target.value;
    fetch(`https://spkjewelry.com/api/get-subdistricts.php?id=${id}`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        document.getElementById("box-sub-districts").style.display = "block";
        document.getElementById("box-zipcode").style.display = "none";
        this.setState({f_districts: {value:id, error:((id!="") ? false : true)} });  
        this.setState({f_sub_district: {value:"", error:false} });  
        this.setState({f_zipcode: {value:"", error:false} });  
        this.setState({dataSubDistricts:data.datas});  
    });

    
  } 

  handleSelectSubDistricts(e) {
    e.preventDefault();
    const id = e.target.value;
    fetch(`https://spkjewelry.com/api/get-zipcode.php?id=${id}`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
        document.getElementById("box-zipcode").style.display = "block";
        this.setState({f_sub_district: {value:id, error:((id!="") ? false : true)} });  
        this.setState({f_zipcode: {value:data.zipcode, error:((data.zipcode!="") ? false : true)} });  

    });
   

    
  } 

    getprovinces() {
        
        fetch('https://spkjewelry.com/api/get-provinces.php', {
                    method: 'GET'
                })
                .then(response => response.json())
                .then(data => {
                    this.setState({dataProvinces:data.datas});
                    
                });
    }
	
	componentDidMount() {
		window.scrollTo(0, 0);
    document.getElementById("box-districts").style.display = "none";
    document.getElementById("box-sub-districts").style.display = "none";
    document.getElementById("box-zipcode").style.display = "none";
    const { ProductId } = this.props.match.params;
    this.setState({pid:ProductId});
    this.GetProductDetail(ProductId);
    this.getprovinces();

	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			//const { ProductCat,ProductK} = this.props.match.params;  
		}
	}



  render() {
    const { classes } = this.props;
      let {
        boxselectDistricts,
        boxselectSubDistricts,
        boxZipcopde,
        isLoaded,
        dataProvinces,
        dataDistricts,
        dataSubDistricts,
        f_name,
        productData,
    } = this.state;

    if (isLoaded) {
            return (
              <>
              <div>
                  <section>
                    <div class="box-loading loader10"> </div>
                  </section>
              </div>
              </>
            )
        } else {
          return (
            <div className="main-body" style={{backgroundColor:'#FFF'}}>
          <div className={classes.paper} >

          <Avatar className={classes.green}>
          <AssignmentIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{marginBottom:'26px'}} justifyContent="flex-start" alignItems="flex-start">
          รายละเอียดสินค้าที่สั่ง
          </Typography>

            <Grid container spacing={1} xs={12} justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={4} sm={2}>
                <img src={"https://spkjewelry.com/products/picture/products/"+productData.id+"/main.jpg"} width="100%" />
              </Grid>
              <Grid item xs={8} sm={10}>
                
                
                  <div style={{display:'block',width:'100%',fontWeight:'600'}}>{productData.name}</div>
                  <div style={{display:'block',width:'100%',fontWeight:'400'}}>{productData.sinfo}</div>
                  <div style={{display:'block',width:'100%',fontSize:'1em',fontWeight:'700',color:'#CD312F'}}>{productData.price_f} </div>
                  <div style={{fontSize:'0.8em',fontWeight:'400',color:'rgba(0,0,0,0.5)',textDecoration:'line-through'}}>ปกติ {productData.rprice_f}</div>
                  <div style={{display:'block',width:'100%',fontSize:'1em',fontWeight:'700',color:'#005555'}}>ยอดชำระ {productData.price_f} </div>
                  
                
              </Grid>
            </Grid>
          </div>


                <div className={classes.paper}>
   

 <Typography component="h1" variant="h5">
 ข้อมูลและที่อยู่การจัดส่งสินค้า
 </Typography>
<form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
 <Grid container spacing={2}>
   <Grid item xs={12} sm={4}>
     <TextField
       autoComplete="f_name"
       name="f_name"
       variant="outlined"
       required
       fullWidth
       label="ชื่อ"
       autoFocus
       value={this.state.f_name.value}
       onChange={this.handleInput}
       error={this.state.f_name.error}
      
     />
   </Grid>
   <Grid item xs={12} sm={4}>
     <TextField
       variant="outlined"
       required
       fullWidth
       label="นามสกุล"
       name="f_lname"
       autoComplete="f_lname"
       value={this.state.f_lname.value}
       onChange={this.handleInput}
       error={this.state.f_lname.error}
       
     />
   </Grid>
   <Grid item xs={12} sm={4}>
     <TextField
       variant="outlined"
       required
       fullWidth
       label="เบอร์โทร"
       name="f_phone"
       autoComplete="f_phone"
       value={this.state.f_phone.value}
       onChange={this.handleInputChangePhone}
       error={this.state.f_phone.error}
       
     />
   </Grid>

   <Box p={2}></Box>

   <Grid item xs={12}>
     <TextField
       variant="outlined"
       required
       fullWidth
       label="ที่อยู่"
       name="f_address"
       autoComplete="f_address"
       value={this.state.f_address.value}
       onChange={this.handleInput}
       error={this.state.f_address.error}
     
       
     />
   </Grid>

   <Grid item xs={12}>
       <FormControl variant="outlined" className={classes.formControl} fullWidth required>
       <InputLabel id="demo-simple-select-outlined-label">จังหวัด</InputLabel>
       <Select
       name="f_provinces"
       label="จังหวัด"
       onChange={this.handleSelectProvinces.bind(this)}
       value={this.state.f_provinces.value}
       error={this.state.f_provinces.error}
       >
       {
               dataProvinces.map((items =>
                 
                   <MenuItem value={items.id}>{items.name_th}</MenuItem>
               ))
               
           }
       </Select>
       </FormControl>
       </Grid>

       <Grid id="box-districts" item xs={12}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth required>
                <InputLabel >อำเภอ</InputLabel>
                <Select
                name="f_districts"
                label="อำเภอ"
                onChange={this.handleSelectDistricts.bind(this)}
                value={this.state.f_districts.value}
                error={this.state.f_districts.error}
                >
                {
                    dataDistricts.map((items =>
                        
                        <MenuItem value={items.id}>{items.name_th}</MenuItem>
                    ))
                    
                }
                </Select>
                </FormControl>
                </Grid>

            <Grid id="box-sub-districts" item xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth required>
              <InputLabel >ตำบล</InputLabel>
              <Select
              name="f_sub_district"
              onChange=""
              label="ตำบล"
              onChange={this.handleSelectSubDistricts.bind(this)}
              value={this.state.f_sub_district.value}
              error={this.state.f_sub_district.error}
              >

              {
                  dataSubDistricts.map((items =>
                      
                      <MenuItem value={items.id}>{items.name_th}</MenuItem>
                  ))
                  
              }
              </Select>
              </FormControl>
              </Grid>
              <Grid id="box-zipcode" item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="รหัสไปรษณีย์"
                  name="f_zipcode" 
                  onChange={this.handleInput}
                  value={this.state.f_zipcode.value}
                  error={this.state.f_zipcode.error}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

           <Box p={2}></Box>
          
   <Grid item xs={12}>
   <FormControl variant="outlined" className={classes.formControl} fullWidth required>
     <TextField
       variant="outlined"
       fullWidth
       label="แจ้งรายละเอียดการสั่งซื้อ"
       name="f_info" 
       value={this.state.f_info.value}
       onChange={this.handleInput}
     />
     <FormHelperText>เช่น ไซส์แหวน: (กรณีสั่งแหวน) หรือ ขนาดกำไล-ข้อมือ (กรณีสั่งกำไล-ข้อมือ) หรือ สิ่งอื่นๆที่ท่านต้องการ</FormHelperText>
     </FormControl>
   </Grid>



       


   <Grid item xs={12}>

   <FormControl variant="outlined" className={classes.formControl} fullWidth required>
<InputLabel id="demo-simple-select-outlined-label">วิธีชำระเงิน</InputLabel>
<Select
   name="f_paysty"
   onChange=""
   label="วิธีชำระเงิน"
   value={this.state.f_paysty.value}
   onChange={this.handleInput}
   error={this.state.f_paysty.error}
>
 <MenuItem value={1}>เก็บเงินปลายทาง</MenuItem>
 <MenuItem value={2}>โอนเงิน (ผ่านบัญชีธนาคาร)</MenuItem>
 <MenuItem value={3}>ผ่านบัตรเครดิต / บัตรเดบิต (PAYPAL)</MenuItem>
</Select>
<FormHelperText>ราคาสินค้ามากกว่า 3,000 บาท ไม่สามารถเลือกชำระเงินปลายทางได้</FormHelperText>
</FormControl>
   </Grid>
   
 </Grid>
 <Button
   type="submit"
   fullWidth
   variant="contained"
   color="primary"
   className={classes.submit}
 >
   สั่งซื้อสินค้า
 </Button>
 
</form>

</div>
            </div>
          );
      }


  }
}
export default withStyles(styles, { withTheme: true })(Checkout);