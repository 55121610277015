import React from 'react'
import {Link} from 'react-router-dom'
import {isMobile} from 'react-device-detect';






export default function footer() {
    return (
        <>
        <div class="footer-spacer"></div>
            <footer>
                <div class="footer">
                    <ul>
                        <li class="footer-left" > 
                            <div class="footer-logo">&nbsp;</div>
                            
                        </li>
                        <li class="footer-left" >
                            <div class="footer-box-right">
                            <div class="footer-line-qrcode-top">@spkjewel</div>
                            <div class="footer-line-qrcode">&nbsp;</div>
                            <div class="footer-line-qrcode-bottom">LINE@ Add Friend</div>
                            </div>
                        </li>
                        <li class="footer-center" >  
                            <div class="footer-box-center"> 
                                <div class="footer-social-header"><span>ช่องทางติดตามเรา</span></div>
                                <div class="footer-btn-social footer-logo-facebook"><a href="">Like us on Facebook</a></div>
                                <div class="footer-btn-social footer-logo-messenger"><a href="">Chat on Messenger</a></div>
                                <div class="footer-btn-social footer-logo-line"><a href="">LINE@ Add Friend</a></div>
                                <div class="footer-btn-social footer-logo-tiktok"><a href="">Make Your Day TikTok</a></div>
                                <div class="footer-btn-social footer-logo-twitter"><a href="">Followers us on Twitter</a></div>
                                <div class="footer-btn-social footer-logo-instagram"><a href="">Instagram</a></div>
                            </div>
                        </li>
                        <li class="footer-right" >
                            <div class="footer-box-right">
                                <div class="footer-right-title">ศูนย์เพชร ส.วรกิจ เอส พี เค เจมส์</div>
                                <div class="footer-right-info">เรามุ่งมันที่จะรักษาคุณภาพให้ได้มาตรฐานและสร้างความพึงพอใจให้กับลูกค้าของเรามากที่สุด ทีมงานของเราออกแบบและคัดสรรเพชร-พลอยที่มีคุฯภาพบรรจงฝังลงบนตัวเรือนที่มีทีมงานของเราเป็นผู้ออกแบบ เราใส่ใจในขั้นตอนและกระบวนการผลิตอย่างพิถีพิถันในการผลิตสินค้าด้วยเครื่องมือในการผลิตเทคโนโลยีที่ทันสมัยจากอิตาลีและช่างผู้ชำนาญงานด้านอัญมณีกว่า 20 ปี พร้อมกระนั้นเรายังเป็นผู้นำเข้าเพชรแท้ พร้อม Certificate จากสภาบันชั้นนำของโลก อาทิ GIA, HRD, IGI เป็นต้น เราเชื่อว่าลูกค้าของเราจะได้รับสินค้าที่ได้มาตรฐานและในราคาที่พึงพอใจสูงสุด เราพร้อมที่จะมีโอกาสได้ผลิตสินค้าเครื่องประดับให้กับลูกค้าคนสำคัญของเรา</div>
                            </div>
                        </li>
                    </ul> 
                   
                </div>
                <div class="footer-bar">
                    <p>
                    © 2013 SPK Gold & Jewelry Co., Ltd. All Rights Reserved  <span>Power by KK</span> <div id="google_translate_element"></div>
                    </p>
                </div>
            </footer>
        </>
    )

    /*
    if(isMobile) {
        return (
            <>
                <div class="footer-spacer"></div>
                <footer>
                    <div class="footer-mobile">
                        <ul>
                            <li class="footer-left"> 
                                <div class="footer-box"> 
                                    <div class="footer-logo">&nbsp;</div>
                                </div>
                            </li>
                            <li class="footer-right">
                                <div class="footer-box"> 
                                <div class="footer-line-qrcode-top">@spkjewel</div>
                                <div class="footer-line-qrcode">&nbsp;</div>
                                <div class="footer-line-qrcode-bottom">LINE@ Add Friend</div>
                                </div>
                            </li>
                            <li class="footer-center">  
                                <div class="footer-box"> 
                                    <div class="footer-social-header"><span>ช่องทางติดตามเรา</span></div>
                                    <div class="footer-btn-social footer-logo-facebook"><a href="">Like us on Facebook</a></div>
                                    <div class="footer-btn-social footer-logo-messenger"><a href="">Chat on Messenger</a></div>
                                    <div class="footer-btn-social footer-logo-line"><a href="">LINE@ Add Friend</a></div>
                                    <div class="footer-btn-social footer-logo-tiktok"><a href="">Make Your Day TikTok</a></div>
                                    <div class="footer-btn-social footer-logo-twitter"><a href="">Followers us on Twitter</a></div>
                                    <div class="footer-btn-social footer-logo-instagram"><a href="">Instagram</a></div>
                                </div>
                            </li>
                            <li class="footer-right">
                                <div class="footer-box"> 
                                    <div class="footer-right-title">ศูนย์เพชร ส.วรกิจ เอส พี เค เจมส์</div>
                                    <div class="footer-right-info">เรามุ่งมันที่จะรักษาคุณภาพให้ได้มาตรฐานและสร้างความพึงพอใจให้กับลูกค้าของเรามากที่สุด ทีมงานของเราออกแบบและคัดสรรเพชร-พลอยที่มีคุฯภาพบรรจงฝังลงบนตัวเรือนที่มีทีมงานของเราเป็นผู้ออกแบบ เราใส่ใจในขั้นตอนและกระบวนการผลิตอย่างพิถีพิถันในการผลิตสินค้าด้วยเครื่องมือในการผลิตเทคโนโลยีที่ทันสมัยจากอิตาลีและช่างผู้ชำนาญงานด้านอัญมณีกว่า 20 ปี พร้อมกระนั้นเรายังเป็นผู้นำเข้าเพชรแท้ พร้อม Certificate จากสภาบันชั้นนำของโลก อาทิ GIA, HRD, IGI เป็นต้น เราเชื่อว่าลูกค้าของเราจะได้รับสินค้าที่ได้มาตรฐานและในราคาที่พึงพอใจสูงสุด เราพร้อมที่จะมีโอกาสได้ผลิตสินค้าเครื่องประดับให้กับลูกค้าคนสำคัญของเรา</div>
                                </div>
                            </li>
                        </ul> 
                        <div class="footer-bar">© 2013 SPK Gold & Jewelry Co., Ltd. All Rights Reserved  <span>Power by KK</span> <div id="google_translate_element"></div></div>
                    </div>
                
                </footer>
            </>
        )
    }else{
        return (
            <>
            <div class="footer-spacer"></div>
                <footer>
                    <div class="footer">
                        <ul>
                            <li class="footer-left" style={{width:'20%'}}> 
                                <div class="footer-logo">&nbsp;</div>
                                
                            </li>
                            <li class="footer-right" style={{width:'24%'}}>
                                <div class="footer-box-right">
                                <div class="footer-line-qrcode-top">@spkjewel</div>
                                <div class="footer-line-qrcode">&nbsp;</div>
                                <div class="footer-line-qrcode-bottom">LINE@ Add Friend</div>
                                </div>
                            </li>
                            <li class="footer-center" style={{width:'24%'}}>  
                                <div class="footer-box-center"> 
                                    <div class="footer-social-header"><span>ช่องทางติดตามเรา</span></div>
                                    <div class="footer-btn-social footer-logo-facebook"><a href="">Like us on Facebook</a></div>
                                    <div class="footer-btn-social footer-logo-messenger"><a href="">Chat on Messenger</a></div>
                                    <div class="footer-btn-social footer-logo-line"><a href="">LINE@ Add Friend</a></div>
                                    <div class="footer-btn-social footer-logo-tiktok"><a href="">Make Your Day TikTok</a></div>
                                    <div class="footer-btn-social footer-logo-twitter"><a href="">Followers us on Twitter</a></div>
                                    <div class="footer-btn-social footer-logo-instagram"><a href="">Instagram</a></div>
                                </div>
                            </li>
                            <li class="footer-right" style={{width:'32%'}}>
                                <div class="footer-box-right">
                                    <div class="footer-right-title">ศูนย์เพชร ส.วรกิจ เอส พี เค เจมส์</div>
                                    <div class="footer-right-info">เรามุ่งมันที่จะรักษาคุณภาพให้ได้มาตรฐานและสร้างความพึงพอใจให้กับลูกค้าของเรามากที่สุด ทีมงานของเราออกแบบและคัดสรรเพชร-พลอยที่มีคุฯภาพบรรจงฝังลงบนตัวเรือนที่มีทีมงานของเราเป็นผู้ออกแบบ เราใส่ใจในขั้นตอนและกระบวนการผลิตอย่างพิถีพิถันในการผลิตสินค้าด้วยเครื่องมือในการผลิตเทคโนโลยีที่ทันสมัยจากอิตาลีและช่างผู้ชำนาญงานด้านอัญมณีกว่า 20 ปี พร้อมกระนั้นเรายังเป็นผู้นำเข้าเพชรแท้ พร้อม Certificate จากสภาบันชั้นนำของโลก อาทิ GIA, HRD, IGI เป็นต้น เราเชื่อว่าลูกค้าของเราจะได้รับสินค้าที่ได้มาตรฐานและในราคาที่พึงพอใจสูงสุด เราพร้อมที่จะมีโอกาสได้ผลิตสินค้าเครื่องประดับให้กับลูกค้าคนสำคัญของเรา</div>
                                </div>
                            </li>
                        </ul> 
                        <div class="footer-bar">© 2013 SPK Gold & Jewelry Co., Ltd. All Rights Reserved  <span>Power by KK</span> <div id="google_translate_element"></div></div>
                    </div>
                
                </footer>
            </>
        )
        
    }*/
}